<template>
  <div>
    <!-- Task Card Header -->
    <b-card
      class="card"
      title="Detail Tugas"
    >
      <div class="mt-2">
        <b-row>
          <!-- Deskripsi Tugas -->
          <b-col lg="12 mb-1">
            <label class="d-block">Deskripsi Tugas</label>
            <b-form-textarea
              v-model="formData.taskDescription"
              placeholder="Deskripsi Tugas"
              rows="3"
              no-resize
              :state="formErrors.taskDescription.state"
              :readonly="!isEditMode"
            />
            <b-form-invalid-feedback v-show="!formErrors.taskDescription.state">
              {{ formErrors.taskDescription.message }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Start Date -->
          <b-col lg="6">
            <label class="d-block">Tanggal Berakhir Tugas </label>
            <b-form-group :state="!formErrors.taskEndDate.state">
              <flat-pickr
                v-model="formData.taskEndDate"
                class="form-control"
                :class="{
                  'is-invalid': formErrors.taskEndDate.state,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  enableTime: true,
                  time_24hr: true,
                }"
                :disabled="!isEditMode"
              />
            </b-form-group>
            <div
              v-show="!formErrors.taskEndDate.state"
              class="invalid-feedback"
            >
              {{ formErrors.taskEndDate.message }}
            </div>
          </b-col>

          <!-- Jenis Tugas -->
          <b-col lg="12">
            <label class="d-block">Pilih Jenis Tugas</label>
            <b-row class="mt-0">
              <!-- Essay -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedTaskType === 'Essay'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('Essay')"
                >
                  <div class="d-flex flex-column align-items-center p-2">
                    <b-avatar
                      size="lg"
                      rounded="sm"
                      variant="light-info"
                    >
                      <i class="card-icon-lg ti ti-notes" />
                    </b-avatar>
                    <h4 class="pt-1">
                      Essay
                    </h4>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedTaskType"
                      class="custom-control-primary"
                      value="Essay"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>
              <!-- Multiple Choice -->
              <b-col lg="4">
                <b-card
                  class="mt-1 card-material-type"
                  :class="
                    selectedTaskType === 'Pilihan Ganda'
                      ? 'active-material-type border-primary'
                      : 'border-light-secondary'
                  "
                  @click="triggerFormInput('Pilihan Ganda')"
                >
                  <div class="d-flex flex-column align-items-center p-2">
                    <b-avatar
                      size="lg"
                      rounded="sm"
                      variant="light-success"
                    >
                      <i class="card-icon-lg ti ti-list" />
                    </b-avatar>
                    <h4 class="pt-1">
                      Pilihan Ganda
                    </h4>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="selectedTaskType"
                      class="custom-control-primary"
                      value="Pilihan Ganda"
                      plain
                    />
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <!-- File Extension Input -->
          <!-- <b-col v-show="selectedTaskType === 'file'" lg="12">
              <label class="d-block">Ekstensi File</label>
              <div>
                <b-form-radio v-model="taskFileExtension" plain value="file">
                  File (Dokumen / Video)
                </b-form-radio>
                <b-form-radio v-model="taskFileExtension" plain value="link">
                  Link (Google Drive / Youtube)
                </b-form-radio>
              </div>
            </b-col> -->

          <!-- Essay Question -->
          <b-col
            v-show="selectedTaskType === 'Essay'"
            lg="12"
          />

          <!-- Multiple Choice Question-->
          <b-col
            v-show="selectedTaskType === 'Pilihan Ganda'"
            lg="12"
          />
        </b-row>

        <div class="mt-1">
          <b-button
            v-show="!isEditMode"
            variant="warning"
            class="float-right ml-1"
            @click="isEditMode = true"
          >
            <div class="d-flex align-items-center">
              <i class="ti ti-pencil small-gap-right" />
              Edit
            </div>
          </b-button>
          <b-button
            v-show="isEditMode"
            variant="primary"
            class="float-right ml-1"
            @click="submitForm"
          >
            <div class="d-flex align-items-center">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i
                v-show="!isLoading"
                class="ti ti-device-floppy small-gap-right"
              />
              Simpan
            </div>
          </b-button>
          <b-button
            v-show="isEditMode"
            variant="secondary"
            class="float-right ml-1"
            @click="isEditMode = false"
          >
            <div class="d-flex align-items-center">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i
                v-show="!isLoading"
                class="ti ti-arrow-left small-gap-right"
              />
              Batal
            </div>
          </b-button>
          <b-button
            v-show="!isEditMode"
            variant="danger"
            class="float-right ml-1"
            @click="deleteTask()"
          >
            <div class="d-flex align-items-center">
              <i class="ti ti-trash small-gap-right" />
              Hapus
            </div>
          </b-button>
          <b-button
            v-show="!isEditMode"
            variant="info"
            class="float-right ml-1"
            :to="{
              name:
                selectedTaskType == 'Pilihan Ganda'
                  ? checkAuthorizeRole('task-multiplechoice-questions.show')
                  : checkAuthorizeRole('task-essay-questions.show'),
              params: {
                trainingId: this.$route.params.trainingId,
                chapterId: this.$route.params.chapterId,
                taskId: formData.id,
              },
            }"
          >
            <div class="d-flex align-items-center">
              <i class="ti ti-help small-gap-right" />
              Daftar Soal
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'

// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import {
  reqUpdateTask,
  reqGetChapterTask,
  reqDeleteTask,
} from '@/api/admin/task'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BAlert,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      // state
      isLoading: false,
      selectedTaskType: 'file',
      taskFileExtension: 'file',
      isEditMode: false,

      // default data
      trainingId: this.$router.currentRoute.params.trainingId,
      chapterId: this.$router.currentRoute.params.chapterId,

      // form data
      formErrors: {
        taskTitle: { state: null, message: '' },
        taskDescription: { state: null, message: '' },
        taskEndDate: { state: null, message: '' },
      },
      formData: {
        id: '',
        taskTitle: '',
        taskDescription: '',
        taskEndDate: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumb[1].params = {
      trainingId: to.params.trainingId,
      chapterId: to.params.chapterId,
    }
    next()
  },
  mounted() {
    this.getTask()
  },
  methods: {
    getTask() {
      reqGetChapterTask(this.$route.params.chapterId).then(response => {
        this.formData.id = response.data.data.id
        this.selectedTaskType = response.data.data.type
        this.formData.taskDescription = response.data.data.description
        this.formData.taskEndDate = new Date(response.data.data.end_at)
          .toISOString()
          .slice(0, 10)
      })
    },
    deleteTask() {
      reqDeleteTask(this.formData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Berhasil menghapus soal',
              variant: 'success',
            },
          })
          this.$router.push({
            name: checkAuthorizeRole('trainings.show'),
            params: { trainingId: this.$route.params.trainingId },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'CheckIcon',
              text: error.response.data.data || 'Gagal menghapus soal',
              variant: 'danger',
            },
          })
        })
    },
    avatarText,
    checkAuthorizeRole,
    getExtension(filename) {
      return filename.split('.').pop()
    },
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1,
        )}`
      }
      return text
    },
    fileType(type) {
      const filetype = {
        pdf: 'danger',
        doc: 'info',
        docx: 'info',
        ppt: 'warning',
        pptx: 'warning',
        xls: 'success',
        xlsx: 'success',
        mp4: 'secondary',
        mkv: 'info',
      }

      return filetype[type]
    },
    triggerFormInput(type) {
      this.selectedTaskType = type
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true

      if (this.formData.taskDescription === '') {
        this.formErrors.taskDescription = {
          state: false,
          message: 'Deskripsi Tugas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskDescription = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskEndDate === '') {
        this.formErrors.taskEndDate = {
          state: false,
          message: 'Tanggal Selesai Tugas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskEndDate = {
          state: null,
          message: '',
        }
      }

      if (!isValid) {
        this.showToast('danger', 'XIcon', 'Error', 'Form tidak valid')
        this.isLoading = false
      } else {
        this.isLoading = true
      }

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        // Create form data
        const data = new FormData()
        data.append('description', this.formData.taskDescription)
        data.append('end_at', this.formData.taskEndDate)
        data.append('type', this.selectedTaskType)

        // create content task
        await reqUpdateTask(this.formData.id, data)
          .then(response => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'Check',
              'Berhasil',
              'Tugas berhasil disimpan',
            )
          })
          .catch(error => {
            this.isLoading = false

            // show success toast
            this.showToast(
              'danger',
              'XIcon',
              'Gagal',
              error.response.data.message,
            )
          })
      }
    },
  },
}
</script>

  <style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 2rem;
}

.vertical-scrollable > .row {
  position: relative;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-y: scroll;
}

.active-material-type {
  border-width: 2px !important;
}

.drop-file {
  cursor: pointer;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}
</style>
